import React from 'react';
import Banner from './components/Banner'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage'; // Một trang ví dụ
import AboutPage from './pages/AboutPage'; // Một trang ví dụ
import LoginPage from './pages/LoginPage';

function App() {
  return (
    <Router>
      <div>
        <Banner /> {/* Hiển thị banner trên mọi trang */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/login" element={<LoginPage />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
