import React from 'react';

function HomePage() {
    // Dữ liệu xét tuyển
    const rows = [
      {
        title: 'XÉT TUYỂN HỌC BẠ 2024 - TEST',
        registerDate: '06/06/2024',
        endDate: '08/06/2024',
        announcementDate: '09/06/2024',
        status: 'active', // Trạng thái nút
      },
      {
        title: 'XÉT TUYỂN ĐẠI HỌC CHÍNH QUY NĂM 2024 (HỌC BẠ) - TEST',
        registerDate: '08/06/2024',
        endDate: '09/06/2024',
        announcementDate: '09/06/2024',
        status: 'active',
      },
      {
        title: 'XÉT TUYỂN ĐẠI HỌC CHÍNH QUY NĂM 2024 THEO KẾT QUẢ HỌC TẬP THPT (HỌC BẠ)',
        registerDate: '09/06/2024',
        endDate: '02/07/2024',
        announcementDate: '08/07/2024',
        status: 'inactive',
      },
    ];
  
    return (
      <div className="container my-5">
        <h2 className="mb-4">Danh Sách Xét Tuyển</h2>
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>Tên Xét Tuyển</th>
              <th>Ngày Đăng Ký</th>
              <th>Ngày Kết Thúc</th>
              <th>Ngày Công Bố</th>
              <th>Hành Động</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{row.title}</td>
                <td>{row.registerDate}</td>
                <td>{row.endDate}</td>
                <td>{row.announcementDate}</td>
                <td>
                  {row.status === 'active' ? (
                    <button className="btn btn-primary">Đăng ký</button>
                  ) : (
                    <button className="btn btn-secondary" disabled>
                      Chưa được đăng ký
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  
  export default HomePage;