import React from 'react';

function AboutPage() {
  return (
    <div className="container">
      <h1>About Us</h1>
      <p>This is the about page content.</p>
    </div>
  );
}

export default AboutPage;
