import React from 'react';
import '../styles/Banner.css'

function Banner() {
    return (
        <div className="banner">
            {/* Banner Image */}
            <div className="container my-3">
                <img
                    src={`${process.env.PUBLIC_URL}/images/bannerDHCD.png`}
                    alt="Banner"
                    className="img-fluid"
                />
            </div>

            {/* Navigation Menu */}
            <nav className="container text-center my-3">
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            ĐĂNG KÝ XÉT TUYỂN
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            ĐIỀU CHỈNH NGUYỆN VỌNG
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="http://tuyensinh.dhcd.edu.vn/search">
                            TRA CỨU KẾT QUẢ
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="http://dhcd.edu.vn/b/Tin-Khac/DANH-SACH-DIA-CHI.html">
                            LIÊN HỆ
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-primary" href="/login">
                            Đăng Nhập
                        </a>
                    </li>
                </ul>
            </nav>
        </div>

    );
}

export default Banner;